import React, { useState, useEffect } from "react";
import { db } from "../../firebaseConfig";
import {
    collection,
    getDocs,
    deleteDoc,
    doc,
    addDoc,
    updateDoc,
    query,
    orderBy,
    startAfter,
    limit,
} from "firebase/firestore";
import AddItem from "./AddItem";
import UpdateItem from "./UpdateItem";

interface Item {
    id: string;
    name: string;
    Catalog: string;
    price: number;
    stock: string; // For dropdown values
    description: string; // New description property
}

export default function Inventory() {
    const [items, setItems] = useState<Item[]>([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 15;
    const [lastVisible, setLastVisible] = useState<any>(null);

    const [hasMore, setHasMore] = useState(true);

    const [showAddModal, setShowAddModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [currentItem, setCurrentItem] = useState<Item | null>(null);
    const [itemToDelete, setItemToDelete] = useState<string | null>(null);

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const docId = localStorage.getItem("docId");
                if (!docId) {
                    console.error("Document ID not found in localStorage.");
                    return;
                }

                const userItemsCollection = collection(db, `Vendors/${docId}/Inventory`);
                let q = query(userItemsCollection, orderBy("name"), limit(itemsPerPage));

                if (lastVisible) {
                    q = query(userItemsCollection, orderBy("name"), startAfter(lastVisible), limit(itemsPerPage));
                }

                const querySnapshot = await getDocs(q);
                const itemsData = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...(doc.data() as Omit<Item, "id">),
                }));
                if (itemsData.length < itemsPerPage) {
                    setHasMore(false);
                }
                setItems(itemsData);
                setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
            } catch (error) {
                console.error("Error fetching items: ", error);
            }
        };

        fetchItems();
    }, [currentPage]);

    const handleDeleteButton = (id: string) => {
        setItemToDelete(id);
        setShowConfirmModal(true);
    };

    const handleDeleteItem = async () => {
        try {
            const docId = localStorage.getItem("docId");
            if (!docId || !itemToDelete) {
                console.error("Document ID or Item ID not found.");
                return;
            }

            await deleteDoc(doc(db, `Vendors/${docId}/Inventory`, itemToDelete));
            setItems((prevItems) => prevItems.filter((item) => item.id !== itemToDelete));
            setItemToDelete(null);
        } catch (error) {
            console.error("Error deleting item: ", error);
        }
    };

    const handleAddItem = async (newItem: Omit<Item, "id">) => {
        try {
            const docId = localStorage.getItem("docId");
            if (!docId) {
                console.error("Document ID not found in localStorage.");
                return;
            }

            const docRef = await addDoc(
                collection(db, `Vendors/${docId}/Inventory`),
                newItem
            );
            setItems([...items, { ...newItem, id: docRef.id }]);
            setShowAddModal(false);
        } catch (error) {
            console.error("Error adding item:", error);
        }
    };

    const handleUpdateItem = async (updatedItem: Item) => {
        try {
            const docId = localStorage.getItem("docId");
            if (!docId) {
                console.error("Document ID not found in localStorage.");
                return;
            }

            const itemRef = doc(db, `Vendors/${docId}/Inventory`, updatedItem.id);
            const { id, ...updateData } = updatedItem;
            await updateDoc(itemRef, updateData);
            setItems(
                items.map((item) => (item.id === updatedItem.id ? updatedItem : item))
            );
            setShowUpdateModal(false);
        } catch (error) {
            console.error("Error updating item:", error);
        }
    };
    const handleNextPage = () => {
        if (hasMore) setCurrentPage(currentPage + 1);
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            setLastVisible(null); // Reset lastVisible to fetch the first page again
        }
    };
    const filteredItems = items.filter(
        (item) =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.Catalog.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.description.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="main-panel">
            <div className="content">
                <div className="container-fluid mt-3 p-3">
                    <h4 className="page-title mb-4">Item Inventory</h4>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-tasks">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    <div className="flex justify-between items-center mb-4">
                                        <input
                                            type="text"
                                            placeholder="Search here..."
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            className="border border-gray-300 rounded-lg px-4 py-2 w-1/3 focus:outline-none focus:ring focus:border-blue-500"
                                        />
                                    </div>
                                    <button onClick={() => setShowAddModal(true)} className="btn btn-primary btn-sm">
                                        Add New Item
                                    </button>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive px-5 py-4 table-striped">
                                        <table className="table table-bordered table-hover ">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Name</th>
                                                    <th>Catalog</th>
                                                    <th>Price</th>
                                                    <th>Stock</th>
                                                    <th>Description</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filteredItems.map((item, index) => (
                                                    <tr key={item.id}>
                                                        <td className="py-2 px-4">{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                        <td>{item.name}</td>
                                                        <td>{item.Catalog}</td>
                                                        <td>{item.price}</td>
                                                        <td>
                                                            <select
                                                                style={{
                                                                    padding: "5px 10px",
                                                                    border: "1px solid #ced4da",
                                                                    borderRadius: "4px",
                                                                    fontSize: "14px",
                                                                    backgroundColor: "#f8f9fa",
                                                                    color: "#495057",
                                                                    outline: "none",
                                                                    cursor: "pointer",
                                                                    transition: "all 0.3s ease",
                                                                }}
                                                                value={item.stock}
                                                                onChange={(e) =>
                                                                    handleUpdateItem({
                                                                        ...item,
                                                                        stock: e.target.value,
                                                                    })
                                                                }
                                                            >
                                                                <option
                                                                    style={{
                                                                        color: "#495057",
                                                                        backgroundColor: "#fff",
                                                                    }}
                                                                    value="Available"
                                                                >
                                                                    Available
                                                                </option>
                                                                <option
                                                                    style={{
                                                                        color: "#495057",
                                                                        backgroundColor: "#fff",
                                                                    }}
                                                                    value="Out of Stock"
                                                                >
                                                                    Out of Stock
                                                                </option>
                                                            </select>
                                                        </td>
                                                        <td>{item.description}</td>
                                                        <td className="td-actions">
                                                            <div className="form-button-action">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-link btn-success"
                                                                    onClick={() => {
                                                                        setCurrentItem(item);
                                                                        setShowUpdateModal(true);
                                                                    }}
                                                                >
                                                                    <i className="fas fa-edit" aria-hidden="true"></i>
                                                                    <span className="sr-only">Edit</span>
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    onClick={() => handleDeleteButton(item.id)}
                                                                    className="btn btn-link btn-danger"
                                                                >
                                                                    <i className="fas fa-trash-alt" aria-hidden="true"></i>
                                                                    <span className="sr-only">Delete</span>
                                                                </button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <div className="d-flex mt-4 justify-content-end align-items-center gap-2">
                                            <button
                                                disabled={currentPage === 1}
                                                onClick={handlePreviousPage}
                                                className={`btn ${currentPage === 1 ? "btn-secondary disabled" : "btn-primary"}`}
                                            >
                                                Previous
                                            </button>
                                            <span className="mx-2">
                                                Page {currentPage}
                                            </span>
                                            <button
                                                onClick={handleNextPage}
                                                className={`btn ${!hasMore ? "btn-secondary disabled" : "btn-primary"}`}
                                            >
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showAddModal && (
                <AddItem onClose={() => setShowAddModal(false)} onAdd={handleAddItem} />
            )}
            {showUpdateModal && currentItem && (
                <UpdateItem
                    item={currentItem}
                    onClose={() => setShowUpdateModal(false)}
                    onUpdate={handleUpdateItem}
                />
            )}
            {itemToDelete && (
                <div className="modal" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Confirm Deletion</h5>
                                <button
                                    type="button"
                                    className="close"
                                    onClick={() => setItemToDelete(null)}
                                    style={{ background: "none", border: "none", fontSize: "1.5rem", color: "#000" }}
                                >
                                    &times;
                                </button>
                            </div>
                            <div className="modal-body">
                                Are you sure you want to delete this item?
                            </div>
                            <div className="modal-footer">
                                <button
                                    className="btn btn-secondary"
                                    onClick={() => setItemToDelete(null)}
                                >
                                    Cancel
                                </button>
                                <button className="btn btn-danger" onClick={handleDeleteItem}>
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
