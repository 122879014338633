import React, { useState } from "react";
import { Alert, Row, Col, Form, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { registerUserAccount } from "../../services/auth"; // Import the registerUserAccount function
import NonAuthLayoutWrapper from "../../components/NonAutnLayoutWrapper";
import AuthHeader from "../../components/AuthHeader";
import FormInput from "../../components/FormInput";

interface RegisterFormValues {
  fullName: string;
  companyName: string;
  mobileNumber: string;
  email: string;
  password: string;
}

const Register = () => {
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const resolver = yupResolver(
    yup.object().shape({
      fullName: yup.string().required("Please enter your full name."),
      companyName: yup.string().required("Please enter your company name."),
      mobileNumber: yup
        .string()
        .matches(/^[0-9]{10,15}$/, "Please enter a valid mobile number.")
        .required("Please enter your mobile number."),
      email: yup
        .string()
        .email("This value should be a valid email.")
        .required("Please enter an email."),
      password: yup.string().required("Please enter a password."),
    }),
  );

  const methods = useForm({ resolver });
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = methods;

  const onSubmitForm = async (values: RegisterFormValues) => {
    setIsSubmitting(true);
    const additionalData = {
      fullName: values.fullName,
      companyName: values.companyName,
      mobileNumber: values.mobileNumber,
    };

    try {
      // Call registerUserAccount function
      const result = await registerUserAccount(
        values.email,
        values.password,
        additionalData,
      );

      if (result.success) {
        setAlertMessage("User registered successfully!");
        setAlertType("success");
        setTimeout(() => navigate("/auth-login"), 3000); // Redirect after 3 seconds
      } else {
        setAlertMessage(
          result.message || "An error occurred during registration.",
        );
        setAlertType("danger");
      }
    } catch (error) {
      console.error("Error during registration: ", error);
      setAlertMessage(
        "An error occurred while registering. Please try again later.",
      );
      setAlertType("danger");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <NonAuthLayoutWrapper>
      <Row className="justify-content-center my-auto">
        <Col sm={8} lg={6} xl={5} className="col-xxl-4">
          <div className="py-md-5 py-4">
            <AuthHeader
              title="Register Account"
              subtitle="Get your free Doot account now."
            />

            {alertMessage && (
              <Alert color={alertType} toggle={() => setAlertMessage("")}>
                {alertMessage}
              </Alert>
            )}

            <Form
              onSubmit={handleSubmit(onSubmitForm)}
              className="position-relative"
            >
              <div className="mb-3">
                <FormInput
                  label="Full Name"
                  type="text"
                  name="fullName"
                  register={register}
                  errors={errors}
                  control={control}
                  labelClassName="form-label"
                  placeholder="Enter Full Name"
                  className="form-control"
                />
              </div>

              <div className="mb-3">
                <FormInput
                  label="Company Name"
                  type="text"
                  name="companyName"
                  register={register}
                  errors={errors}
                  control={control}
                  labelClassName="form-label"
                  placeholder="Enter Company Name"
                  className="form-control"
                />
              </div>

              <div className="mb-3">
                <FormInput
                  label="Mobile Number"
                  type="text"
                  name="mobileNumber"
                  register={register}
                  errors={errors}
                  control={control}
                  labelClassName="form-label"
                  placeholder="Enter Mobile Number"
                  className="form-control"
                />
              </div>

              <div className="mb-3">
                <FormInput
                  label="Email"
                  type="text"
                  name="email"
                  register={register}
                  errors={errors}
                  control={control}
                  labelClassName="form-label"
                  placeholder="Enter Email"
                  className="form-control"
                />
              </div>

              <div className="mb-3">
                <FormInput
                  label="Password"
                  type="password"
                  name="password"
                  register={register}
                  errors={errors}
                  control={control}
                  labelClassName="form-label"
                  className="form-control pe-5"
                  placeholder="Enter Password"
                />
              </div>

              <div className="mb-4">
                <p className="mb-0">
                  By registering you agree to the Doot{" "}
                  <a href="#" className="text-primary">
                    Terms of Use
                  </a>
                </p>
              </div>

              <div className="text-center mb-3">
                <Button
                  color="primary"
                  className="w-100 waves-effect waves-light"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Processing..." : "Register"}
                </Button>
              </div>
            </Form>

            <div className="mt-5 text-center text-muted">
              <p>
                Already have an account?{" "}
                <a
                  href="/auth-login"
                  className="fw-medium text-decoration-underline"
                >
                  Login
                </a>
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </NonAuthLayoutWrapper>
  );
};

export default Register;
