import { useState, useEffect } from "react";
import { auth, db } from "../firebaseConfig";
import {
  doc,
  setDoc,
  getDoc,
  onSnapshot,
  collection,
  orderBy,
  updateDoc,
  query,
  getDocs,
  addDoc,
  serverTimestamp,
  DocumentData,
} from "firebase/firestore";
import { Message } from "../types/Chat";
import AWS from "aws-sdk";
import ReactS3Client from "react-aws-s3-typescript";
import { s3Config } from "../s3Config";

const S3_BUCKET = "doot-test-25";
const REGION = "eu-north-1";
const ACCESS_KEY = "AKIA4IM3HEPWALSUVVM6";
const SECRET_ACCESS_KEY = "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";

const getDocumentData = async (
  db: any,
  collectionName: string,
  documentId: string,
) => {
  const docRef = doc(db, collectionName, documentId);
  const docSnap = await getDoc(docRef);

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return undefined;
  }
};

const getMessages = async (db: any, vendorId: string, chatId: string) => {
  const userDocRef = doc(db, "Vendors", vendorId);
  const chatDocRef = doc(userDocRef, "Chats", chatId);
  const messagesQuery = query(
    collection(chatDocRef, "Messages"),
    orderBy("timestamp", "asc"),
  );

  const querySnapshot = await getDocs(messagesQuery);
  const messages = querySnapshot.docs.map(doc => ({
    id: doc.id,
    ...doc.data(),
  }));

  return messages;
};

function checkConversationExists(vendorId: string, chats: any[]) {
  for (var i = 0; i < chats.length; i++) {
    if (chats[i].other_user === vendorId) {
      return true;
    }
  }
  return false;
}

function createConversationId(userId1: string, userId2: string): string {
  const ids = [userId1, userId2].sort();
  return ids.join("-");
}

// const getAllVendors = async () => {
//   var vendors: DocumentData[] = [];
//   const vendorsData = await getDocs(collection(db, "Vendors"));
//   vendorsData.forEach(doc => {
//     const data = doc.data();
//     if (data.email) {
//       vendors.push({ ...data, id: doc.id });
//     }
//   });

//   return vendors;
// };

const getDirectMessages = async (user_uid: string, vendors: DocumentData[]) => {
  var direct_chats: DocumentData[] = [];
  const userRef = doc(db, "Vendors", user_uid);
  const chatCollectionRef = collection(userRef, "Chats");

  const chatsData = await getDocs(chatCollectionRef);
  chatsData.forEach(document => {
    const data = document.data();

    direct_chats.push({
      chat_id: document.id,
      display_type: data.display_type ?? "",
      status: data.status ?? 0,
      mobileNumber: data.WAPhone ?? "",
      email: data.email ?? "",
      fullName:
        data.display_name ??
        `${data.display_type ?? ""}(${data.WAPhone ?? ""})`,
      id: data.other_user ?? "Test",
    });
    // const data = document.data();
    // const vendor = vendors.find(item => item.id === data.other_user);
    // if (vendor)
    //   direct_chats.push({
    //     ...vendor,
    //     chat_id: document.id,
    //     status: data.status,
    //     display_type: data.display_type,
    //   });
  });

  return direct_chats;
};

function searchItems(items: any, searchText: string) {
  return items.filter((item: any) => {
    return (
      item.fullName?.toLowerCase().includes(searchText.toLowerCase()) ||
      item.email?.toLowerCase().includes(searchText.toLowerCase()) ||
      item.mobileNumber?.toLowerCase().includes(searchText.toLowerCase())
    );
  });
}

function getRandomNumber() {
  return Math.floor(Math.random() * 4) + 1;
}

function getRandomRole() {
  const roles = ["employee", "driver", "customer"];
  const randomIndex = Math.floor(Math.random() * roles.length);
  return roles[randomIndex];
}

const useChats = () => {
  const [loading, setLoading] = useState(true);
  const [contacts, setContacts] = useState<any[]>([]);
  const [directMessages, setDirectMessages] = useState<any[]>([]);
  const [displayType, setDisplayType] = useState("all");
  const [searchText, setSearchText] = useState("");
  const user_uid = localStorage.getItem("docId") as string;

  const getData = async (user_uid: string) => {
    // const vendors = await getAllVendors();
    // setContacts(vendors);
    // const direct_chats = await getDirectMessages(user_uid, vendors);
    // const toDisplay = direct_chats.filter(
    //   item =>
    //     item.display_type === displayType ||
    //     displayType === "all" ||
    //     item.WAPhone,
    // );
    // const chats = searchItems(toDisplay, searchText);
    // setDirectMessages(chats);
    // setLoading(false);
  };

  useEffect(() => {
    getData(user_uid);
    const userDocRef = doc(db, "Vendors", user_uid);
    const chatsCollection = collection(userDocRef, "Chats");

    const unsubscribe = onSnapshot(chatsCollection, querySnapshot => {
      querySnapshot.docChanges().forEach(change => {
        if (change.type === "added") {
          getData(user_uid);
        }
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getData(user_uid);
  }, [displayType, searchText]);

  const createChat = async (participant: string, phone: string | undefined) => {
    const conversationExists = checkConversationExists(
      participant,
      directMessages,
    );

    if (!conversationExists) {
      const user1Ref = doc(db, "Vendors", user_uid);
      const user2Ref = doc(db, "Vendors", participant);

      const senderData = await getDocumentData(db, "Vendors", user_uid);
      const participantData = await getDocumentData(db, "Vendors", participant);

      const chatCollectionRef = collection(user1Ref, "Chats");
      const newChatDocRef = doc(chatCollectionRef);

      if (phone) {
        await setDoc(newChatDocRef, {
          timestamp: new Date(),
          other_user: participant,
          WAPhone: participantData ? participantData.mobileNumber : phone,
          status: getRandomNumber(),
          display_type: getRandomRole(),
        });
      } else {
        await setDoc(newChatDocRef, {
          timestamp: new Date(),
          other_user: participant,
          WAPhone: "",
          status: getRandomNumber(),
          display_type: getRandomRole(),
        });
      }

      const chatCollection2Ref = collection(user2Ref, "Chats");
      const newChatDoc2Ref = doc(chatCollection2Ref);

      await setDoc(newChatDoc2Ref, {
        timestamp: new Date(),
        other_user: user_uid,
        WAPhone: senderData ? senderData.mobileNumber : phone,
        status: getRandomNumber(),
        display_type: getRandomRole(),
      });
    }
  };

  function filterDisplay(
    display_type: "all" | "employee" | "driver" | "customer",
  ) {
    setDisplayType(display_type);
  }

  function search(search_text: string) {
    setSearchText(search_text);
  }

  return {
    createChat,
    loading,
    contacts,
    directMessages,
    filterDisplay,
    search,
  };
};

const useChat = (chat_id?: string) => {
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState<any[]>([]);
  const [otherUser, SetOtherUSer] = useState<any>();
  const user_uid = localStorage.getItem("docId") as string;

  const getData = async (user_uid: string, chat_id: string) => {
    const Messages = await getMessages(db, user_uid, chat_id);
    const userRef = doc(db, "Vendors", user_uid);
    const chatCollectionRef = doc(userRef, "Chats", chat_id);
    const docSnap = await getDoc(chatCollectionRef);
    if (docSnap.exists()) {
      const data = docSnap.data();

      // if (!data.other_user) {
      const otherUserData = {
        ...data,
        status: data.status ?? 2,
        display_type: data.display_type ?? "",
        id: data.other_user ?? "",
        isArchived: false,
      };
      SetOtherUSer(otherUserData);
      // } else {
      //   const otherUserRef = doc(db, "Vendors", data.other_user);

      //   const userData = await getDoc(otherUserRef);
      //   if (userData.exists()) {
      //     const otherUserData = {
      //       ...userData.data(),
      //       status: data.status,
      //       display_type: data.display_type,
      //       id: data.other_user,
      //       isArchived: false,
      //     };
      //     SetOtherUSer(otherUserData);
      //   }
      // }
    }
    setMessages(Messages);
    setLoading(false);
  };

  useEffect(() => {
    if (chat_id) {
      getData(user_uid, chat_id);
      const userDocRef = doc(db, "Vendors", user_uid);
      const chatDocRef = doc(userDocRef, "Chats", chat_id);
      const messagesCollection = collection(chatDocRef, "Messages");

      const unsubscribe = onSnapshot(messagesCollection, querySnapshot => {
        querySnapshot.docChanges().forEach(change => {
          if (change.type === "added") {
            getData(user_uid, chat_id);
          }
        });
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chat_id]);

  const openChat = (chat: string, user_id: any) => {
    localStorage.setItem("chat_id", chat);
    localStorage.setItem("other_user", user_id);
  };

  const getUser = async (participant: string) => {
    const docRef = doc(db, "Vendors", participant);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      console.log("Exists: ", docSnap.data());
      return docSnap.data();
    } else {
      return null;
    }
  };

  async function addMessage(message: any) {
    console.log({ message });
    console.log({ chat_id });
    console.log({ user_uid });
    console.log({ otherUser });
    if (chat_id) {
      // const temp = [...messages];
      // temp.push(message);
      // setMessages(temp);

      const userDocRef = doc(db, "Vendors", user_uid);
      const chatRef = doc(userDocRef, "Chats", chat_id);
      const vendorSnap = await getDoc(userDocRef);
      const companyName = vendorSnap.data()?.companyName;
      const messagesCollection = collection(chatRef, "Messages");
      console.log({ companyName });
      await addDoc(messagesCollection, {
        // to: participantData ? participantData.mobileNumber : "0900000000000",
        timestamp: serverTimestamp(),
        sender: companyName,
        senderId: chat_id,
        ...message,
      });
      // const serverTime = serverTimestamp();
      // const addingInfo = {};

      // const addingInfo = {
      //   entry: [
      //     {
      //       changes: [
      //         {
      //           field: "messages",
      //           value: {
      //             contacts: [
      //               {
      //                 profile: {
      //                   name: companyName,
      //                 },
      //                 wa_id: "",
      //               },
      //           ],
      //             messages: [
      //               {
      //                 ...message,
      //                 // timestamp: serverTime,
      //               },
      //             ],
      //             messaging_product: "whatsapp",
      //             metadata: {
      //               display_phone_number: "",
      //               phone_number_id: "",
      //             },
      //           },
      //         },
      //       ],
      //       id: user_uid,
      //     },
      //   ],
      //   // to: participantData ? participantData.mobileNumber : "0900000000000",
      //   timestamp: serverTime,
      //   isToWB: false,
      // };
      // console.log(addingInfo);
      // await addDoc(messagesCollection, addingInfo);

      // const user2DocRef = doc(db, "Vendors", otherUser.id);
      // const chat2Ref = doc(user2DocRef, "Chats", chat_id);
      // const messagesCollection2 = collection(chat2Ref, "Messages");

      // await addDoc(messagesCollection2, {
      //   entry: [
      //     {
      //       changes: [
      //         {
      //           field: "messages",
      //           value: {
      //             contacts: [
      //               {
      //                 profile: {
      //                   name: "moshood",
      //                 },
      //                 wa_id: "97336361006",
      //               },
      //             ],
      //             messages: [
      //               {
      //                 ...message,
      //               },
      //             ],
      //             messaging_product: "whatsapp",
      //             metadata: {
      //               display_phone_number: "08000000000000",
      //               phone_number_id: "114514005031132",
      //             },
      //           },
      //         },
      //       ],
      //       id: "108555662299811",
      //     },
      //   ],
      //   // to: participantData ? participantData.mobileNumber : "0900000000000",
      //   timestamp: serverTimestamp(),
      //   IsToWB: false,
      // });
    }
  }

  return { addMessage, otherUser, loading, messages, openChat, getUser };
};

export { useChat, useChats };
