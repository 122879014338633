// services/registerUser.ts
import { auth, db } from "../firebaseConfig";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  confirmPasswordReset,
  UserCredential,
} from "firebase/auth";
import { doc, setDoc } from "firebase/firestore";
import { NavigateFunction } from "react-router-dom";
import { UserData } from "../types/User";
import {
  getDocs,
  query,
  where,
  collection,
  updateDoc,
} from "firebase/firestore";

export async function registerUserAccount(
  email: string,
  password: string,
  additionalData: Omit<UserData, "createdAt" | "email">,
): Promise<{ success: boolean; message?: string }> {
  try {
    // Perform user registration logic
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password,
    );
    const user = userCredential.user;

    const userData: UserData = {
      email,
      ...additionalData,
      createdAt: new Date().toISOString(),
    };

    await setDoc(doc(db, "Vendors", user.uid), userData);

    return { success: true };
  } catch (error: any) {
    console.error("Error registering user:", error.message);
    return { success: false, message: error.message };
  }
}

export async function loginUserAccount(
  email: string,
  password: string,
): Promise<{ success: boolean; message?: string; data?: UserCredential }> {
  try {
    // Firebase login
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password,
    );

    return { success: true, message: "Login success", data: userCredential };
  } catch (error: any) {
    console.error("Login error:", error.message);
    return { success: false, message: error.message };
  }
}

export async function resetPassword(
  email: string,
): Promise<{ success: boolean; message: string }> {
  try {
    // Store email in localStorage before sending reset email
    localStorage.setItem("userEmail", email);

    await sendPasswordResetEmail(auth, email);
    return {
      success: true,
      message: "Password reset email sent successfully.",
    };
  } catch (error: any) {
    console.error("Error resetting password:", error.message);
    return { success: false, message: error.message };
  }
}

export async function confirmPwdReset(
  oobCode: string,
  newPassword: string,
): Promise<{ success: boolean; message: string }> {
  try {
    await confirmPasswordReset(auth, oobCode, newPassword);
    return {
      success: true,
      message: "Password was changed successfully.",
    };
  } catch (error: any) {
    console.error("Error changing password:", error.message);
    return { success: false, message: error.message };
  }
}
