import { NavigateFunction } from "react-router-dom";

export const redirectAfterDelay = (
  navigate: NavigateFunction,
  path: string,
  delay: number = 3000, // default delay of 3 seconds
): void => {
  setTimeout(() => {
    navigate(path);
  }, delay);
};
