import React, { useState } from "react";
import { Alert, Row, Col, Form } from "reactstrap";

// hooks
import { useRedux } from "../../hooks/index";
import { createSelector } from "reselect";
import { useNavigate } from "react-router-dom";
// validations
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";

import { useSearchParams } from "react-router-dom"; // To extract URL parameters

// // hooks
// import { useProfile } from "../../hooks";

//actions
import { userChangePassword } from "../../redux/actions";

// components
import NonAuthLayoutWrapper from "../../components/NonAutnLayoutWrapper";
import AuthHeader from "../../components/AuthHeader";
import FormInput from "../../components/FormInput";
import Loader from "../../components/Loader";
import { redirectAfterDelay } from "../../utils/redirectAfterDelay";

//images
import avatar1 from "../../assets/images/users/avatar-1.jpg";

//service
import { confirmPwdReset } from "../../services/auth";

interface ChangePasswordProps {}
const ChangePassword = (props: ChangePasswordProps) => {
  // global store
  const { dispatch, useAppSelector } = useRedux();
  const [searchParams] = useSearchParams(); // Hook to get URL query params
  const oobCode = searchParams.get("oobCode"); // Get the oobCode

  interface ResetPasswordFormValues {
    password: string;
  }

  const errorData = createSelector(
    (state: any) => state.ForgetPassword,
    state => ({
      passwordChanged: state.passwordChanged,
      changepasswordError: state.changepasswordError,
      changePassLoading: state.loading,
    }),
  );

  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  // Inside your component
  const { passwordChanged, changepasswordError, changePassLoading } =
    useAppSelector(errorData);

  const resolver = yupResolver(
    yup.object().shape({
      password: yup.string().required("Please Enter New Password."),
      confirmpassword: yup
        .string()
        .oneOf([yup.ref("password")], "Passwords don't match")
        .required("This value is required."),
    }),
  );

  const defaultValues: any = {};

  const methods = useForm({ defaultValues, resolver });
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = methods;

  const handleRedirect = () => {
    redirectAfterDelay(navigate, "/auth-login", 3000); // Redirects to "/target-page" after 3 seconds
  };

  const onSubmitForm = async (values: ResetPasswordFormValues) => {
    const email = localStorage.getItem("userEmail");

    const code = oobCode || "";

    const response = await confirmPwdReset(code, values.password);
    // If resetPassword is successful, dispatch the success action
    if (response.success) {
      setAlertMessage("Reset password have done successfully!");
      setAlertType("success");
      handleRedirect();
    } else {
      setAlertMessage(response.message || "Reset password was failed.");
      setAlertType("danger");
    }
  };

  // const { userProfile, loading } = useProfile();

  return (
    <NonAuthLayoutWrapper>
      <Row className=" justify-content-center my-auto">
        <Col sm={8} lg={6} xl={5} className="col-xxl-4">
          <div className="py-md-5 py-4">
            <AuthHeader title="Change Password" />
            {/* <div className="user-thumb text-center mb-4">
              <img
                src={avatar1}
                className="rounded-circle img-thumbnail avatar-lg"
                alt="thumbnail"
              />
              <h5 className="font-size-15 mt-3">Kathryn Swarey</h5>
            </div> */}
            {alertMessage && (
              <Alert color={alertType} toggle={() => setAlertMessage("")}>
                {alertMessage}
              </Alert>
            )}

            <Form
              onSubmit={handleSubmit(onSubmitForm)}
              className="position-relative"
            >
              {changePassLoading && <Loader />}

              <div className="mb-3">
                <FormInput
                  label="New Password"
                  type="password"
                  name="password"
                  register={register}
                  errors={errors}
                  control={control}
                  labelClassName="form-label"
                  placeholder="Enter New Password"
                  className="form-control"
                  withoutLabel={true}
                  hidePasswordButton={false}
                />
              </div>
              <div className="mb-3">
                <FormInput
                  label="Confirm New Password"
                  type="password"
                  name="confirmpassword"
                  register={register}
                  errors={errors}
                  control={control}
                  labelClassName="form-label"
                  placeholder="Enter Confirm Password"
                  className="form-control"
                  withoutLabel={true}
                  hidePasswordButton={true}
                />
              </div>

              <div className="text-center mt-4">
                <div className="row">
                  <div className="col-6">
                    <button className="btn btn-primary w-100" type="submit">
                      Save
                    </button>
                  </div>
                  <div className="col-6">
                    <button className="btn btn-light w-100" type="button">
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </NonAuthLayoutWrapper>
  );
};

export default ChangePassword;
