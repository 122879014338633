import React, { useState, useEffect } from "react";
import { db } from "../../firebaseConfig";
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc, query, orderBy, startAfter, limit } from "firebase/firestore";
import AddTransaction from "./AddTransaction";
import UpdateTransaction from "./UpdateTransaction";

export interface Transaction {
    id?: string;
    customerName: string;
    transactionDate: string;
    status: string;
    amount: number;
}

export default function Transactions() {
    const [transactions, setTransactions] = useState<Transaction[]>([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 15;
    const [lastVisible, setLastVisible] = useState<any>(null);
    const [hasMore, setHasMore] = useState(true);

    const [showAddModal, setShowAddModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [currentTransaction, setCurrentTransaction] = useState<Transaction | null>(null);
    const [transactionToDelete, setTransactionToDelete] = useState<string | null>(null);

    useEffect(() => {
        const fetchTransactions = async () => {
            try {
                const docId = localStorage.getItem("docId");

                if (!docId) {
                    console.error("Document ID not found in localStorage.");
                    return;
                }

                const transactionsCollection = collection(db, `Vendors/${docId}/Transactions`);
                let q = query(transactionsCollection, orderBy("transactionDate"), limit(itemsPerPage));

                if (lastVisible) {
                    q = query(transactionsCollection, orderBy("transactionDate"), startAfter(lastVisible), limit(itemsPerPage));
                }

                const querySnapshot = await getDocs(q);
                const transactionsData = querySnapshot.docs.map((doc) => ({
                    id: doc.id,
                    ...(doc.data() as Omit<Transaction, "id">),
                }));

                if (transactionsData.length < itemsPerPage) {
                    setHasMore(false);
                }

                setTransactions(transactionsData);
                setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
            } catch (error) {
                console.error("Error fetching transactions: ", error);
            }
        };

        fetchTransactions();
    }, [currentPage]);

    const handleAddTransaction = async (transaction: Transaction) => {
        try {
            const docId = localStorage.getItem("docId");

            if (!docId) {
                console.error("Document ID not found in localStorage.");
                return;
            }

            const docRef = await addDoc(
                collection(db, `Vendors/${docId}/Transactions`),
                transaction
            );
            setTransactions([...transactions, { ...transaction, id: docRef.id }]);
            setShowAddModal(false);
        } catch (error) {
            console.error("Error adding transaction:", error);
        }
    };

    const handleUpdateTransaction = async (transaction: Transaction) => {
        if (!transaction.id) return;

        try {
            const docId = localStorage.getItem("docId");

            if (!docId) {
                console.error("Document ID not found in localStorage.");
                return;
            }

            const transactionRef = doc(db, `Vendors/${docId}/Transactions`, transaction.id);
            await updateDoc(transactionRef, {
                customerName: transaction.customerName,
                transactionDate: transaction.transactionDate,
                status: transaction.status,
                amount: transaction.amount,
            });
            setTransactions((prevTransactions) =>
                prevTransactions.map((prevTransaction) =>
                    prevTransaction.id === transaction.id ? transaction : prevTransaction
                )
            );
            setShowUpdateModal(false);
        } catch (error) {
            console.error("Error updating transaction:", error);
        }
    };

    const handleDeleteTransaction = async () => {
        if (!transactionToDelete) return;

        try {
            const docId = localStorage.getItem("docId");

            if (!docId) {
                console.error("Document ID not found in localStorage.");
                return;
            }

            await deleteDoc(doc(db, `Vendors/${docId}/Transactions`, transactionToDelete));
            setTransactions((prevTransactions) =>
                prevTransactions.filter((transaction) => transaction.id !== transactionToDelete)
            );
            setTransactionToDelete(null);
        } catch (error) {
            console.error("Error deleting transaction:", error);
        }
    };

    const handleNextPage = () => {
        if (hasMore) setCurrentPage(currentPage + 1);
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
            setLastVisible(null); // Reset lastVisible to fetch the first page again
        }
    };

    const filteredTransactions = transactions.filter(
        (transaction) =>
            transaction.customerName.toLowerCase().includes(searchTerm.toLowerCase()) ||
            transaction.transactionDate.toLowerCase().includes(searchTerm.toLowerCase()) ||
            transaction.status.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="main-panel">
            <div className="content">
                <div className="container-fluid mt-3 p-3">
                    <h4 className="page-title mb-4">Transactions</h4>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-tasks">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    <div className="flex justify-between items-center mb-4">
                                        <input
                                            type="text"
                                            placeholder="Search here..."
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            className="border border-gray-300 rounded-lg px-4 py-2 w-1/3 focus:outline-none focus:ring focus:border-blue-500"
                                        />
                                    </div>
                                    <button
                                        className="btn btn-primary btn-sm"
                                        onClick={() => setShowAddModal(true)}
                                    >
                                        Add New Transaction
                                    </button>
                                </div>
                                <div className="card-body">
                                    <div className="table-responsive px-5 py-4 table-striped">
                                        <table className="table table-bordered table-hover ">
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Customer Name</th>
                                                    <th>Transaction Date</th>
                                                    <th>Status</th>
                                                    <th>Amount</th>
                                                    <th>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filteredTransactions.map((transaction, index) => (
                                                    <tr key={transaction.id}>
                                                        <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                                        <td>{transaction.customerName}</td>
                                                        <td>{transaction.transactionDate}</td>
                                                        <td>{transaction.status}</td>
                                                        <td>{transaction.amount}</td>
                                                        <td className="td-actions">
                                                            <button
                                                                className="btn btn-link btn-success"
                                                                onClick={() => {
                                                                    setCurrentTransaction(transaction);
                                                                    setShowUpdateModal(true);
                                                                }}
                                                            >
                                                                <i className="fas fa-edit"></i>
                                                            </button>
                                                            <button
                                                                className="btn btn-link btn-danger"
                                                                onClick={() => setTransactionToDelete(transaction.id!)}
                                                            >
                                                                <i className="fas fa-trash-alt"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                        <div className="d-flex mt-4 justify-content-end align-items-center gap-2">
                                            <button
                                                disabled={currentPage === 1}
                                                onClick={handlePreviousPage}
                                                className={`btn ${currentPage === 1 ? "btn-secondary disabled" : "btn-primary"}`}
                                            >
                                                Previous
                                            </button>
                                            <span className="mx-2">
                                                Page {currentPage}
                                            </span>
                                            <button
                                                onClick={handleNextPage}
                                                className={`btn ${!hasMore ? "btn-secondary disabled" : "btn-primary"}`}
                                            >
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* Add Transaction Modal */}
            {showAddModal && (
                <AddTransaction
                    onClose={() => setShowAddModal(false)}
                    onAdd={handleAddTransaction}
                />
            )}

            {/* Update Transaction Modal */}
            {showUpdateModal && currentTransaction && (
                <UpdateTransaction
                    transaction={currentTransaction}
                    onClose={() => setShowUpdateModal(false)}
                    onUpdate={handleUpdateTransaction}
                />
            )}

            {/* Delete Confirmation Modal */}
            {transactionToDelete && (
                <div className="modal" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: 'rgba(0,0,0,0.5)' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Confirm Deletion</h5>
                                <button
                                    type="button"
                                    className="close"
                                    onClick={() => setTransactionToDelete(null)}
                                    style={{ background: "none", border: "none", fontSize: "1.5rem", color: "#000" }}
                                >
                                    &times;
                                </button>
                            </div>
                            <div className="modal-body">
                                Are you sure you want to delete this transaction?
                            </div>
                            <div className="modal-footer">
                                <button
                                    className="btn btn-secondary"
                                    onClick={() => setTransactionToDelete(null)}
                                >
                                    Cancel
                                </button>
                                <button className="btn btn-danger" onClick={handleDeleteTransaction}>
                                    Delete
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
