import React, { useState } from "react";

interface Vendor {
    id?: string;
    fullName: string;
    email: string;
    mobileNumber: string;
    companyName: string;
}

interface UpdateVendorProps {
    vendor: Vendor;
    onClose: () => void;
    onUpdate: (vendor: Vendor) => void;
}

export default function UpdateVendor({ vendor, onClose, onUpdate }: UpdateVendorProps) {
    const [updatedVendor, setUpdatedVendor] = useState<Vendor>(vendor);
    const [errorMsg, setErrorMsg] = useState("");

    const handleSubmit = () => {
        if (
            !updatedVendor.fullName ||
            !updatedVendor.email ||
            !updatedVendor.mobileNumber ||
            !updatedVendor.companyName
        ) {
            setErrorMsg("Please fill in all fields correctly.");
            return;
        }
        onUpdate(updatedVendor);
    };

    return (
        <div className="modal" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: 'rgba(0,0,0,0.5)' }}>
            <div className="modal-dialog" style={{ maxWidth: '500px', width: '100%' }}>
                <div className="modal-content" style={{ borderRadius: '8px', boxShadow: '0 4px 8px rgba(0,0,0,0.2)', transition: 'all 0.3s ease' }}>
                    <div className="modal-header" style={{ borderBottom: '1px solid #dee2e6', padding: '1rem' }}>
                        <h5 className="modal-title">Update Vendor</h5>
                        <button type="button" className="close" onClick={onClose}
                            style={{
                                background: "none",
                                border: "none",
                                fontSize: "1.5rem",
                                color: "#000",
                                cursor: 'pointer'
                            }}
                        >
                            <span>&times;</span>
                        </button>
                    </div>
                    <div className="modal-body" style={{ padding: '1rem' }}>
                        <div className="form-group" style={{ marginBottom: '1rem' }}>
                            <label htmlFor="fullName" style={{ marginBottom: '0.5rem', display: 'block' }}>Full Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="fullName"
                                value={updatedVendor.fullName}
                                onChange={(e) =>
                                    setUpdatedVendor({
                                        ...updatedVendor,
                                        fullName: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div className="form-group" style={{ marginBottom: '1rem' }}>
                            <label htmlFor="email" style={{ marginBottom: '0.5rem', display: 'block' }}>Email</label>
                            <input
                                type="email"
                                className="form-control"
                                id="email"
                                value={updatedVendor.email}
                                onChange={(e) =>
                                    setUpdatedVendor({
                                        ...updatedVendor,
                                        email: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div className="form-group" style={{ marginBottom: '1rem' }}>
                            <label htmlFor="mobileNumber" style={{ marginBottom: '0.5rem', display: 'block' }}>Phone Number</label>
                            <input
                                type="text"
                                className="form-control"
                                id="mobileNumber"
                                value={updatedVendor.mobileNumber}
                                onChange={(e) =>
                                    setUpdatedVendor({
                                        ...updatedVendor,
                                        mobileNumber: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div className="form-group" style={{ marginBottom: '1rem' }}>
                            <label htmlFor="companyName" style={{ marginBottom: '0.5rem', display: 'block' }}>Company Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="companyName"
                                value={updatedVendor.companyName}
                                onChange={(e) =>
                                    setUpdatedVendor({
                                        ...updatedVendor,
                                        companyName: e.target.value,
                                    })
                                }
                            />
                        </div>
                        {errorMsg && <div className="alert alert-danger" style={{ marginTop: '1rem' }}>{errorMsg}</div>}
                    </div>
                    <div className="modal-footer" style={{ borderTop: '1px solid #dee2e6', padding: '1rem' }}>
                        <button type="button" className="btn btn-secondary" onClick={onClose}
                            style={{
                                borderRadius: '50px',
                                boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                                transition: 'all 0.3s ease',
                                padding: '0.5rem 1rem'
                            }}
                        >
                            Close
                        </button>
                        <button type="button" className="btn btn-primary" onClick={handleSubmit}
                            style={{
                                borderRadius: '50px',
                                boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                                transition: 'all 0.3s ease',
                                padding: '0.5rem 1rem'
                            }}
                        >
                            Update Vendor
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
