import React, { useEffect, useState } from "react";
import { Alert, Row, Col, Form, Label, Button } from "reactstrap";

import { GoogleLogin } from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";

import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";

import {
  collection,
  query,
  where,
  getDocs,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../../firebaseConfig"; // Import Firebase Firestore config

import config from "../../config";
import { useProfile } from "../../hooks/index";

import NonAuthLayoutWrapper from "../../components/NonAutnLayoutWrapper";
import AuthHeader from "../../components/AuthHeader";
import FormInput from "../../components/FormInput";
import Loader from "../../components/Loader";
import { loginUserAccount } from "../../services/auth";

interface LoginFormValues {
  email: string;
  password: string;
}

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [redirectUrl, setRedirectUrl] = useState("/dashboard");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const url =
      location.state && location.state.from
        ? location.state.from.pathname
        : "/dashboard";
    setRedirectUrl(url);
  }, [location]);

  const resolver = yupResolver(
    yup.object().shape({
      email: yup
        .string()
        .email("Enter a valid email")
        .required("Email is required."),
      password: yup.string().required("Password is required."),
    }),
  );

  const methods = useForm({ resolver });
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = methods;

  const { userProfile, loading } = useProfile();

  const onSubmitForm = async (values: LoginFormValues) => {
    setIsSubmitting(true);
    const loginDetails = await loginUserAccount(values.email, values.password);
    if (loginDetails && loginDetails.data) {
      const user = loginDetails.data.user;

      const docRef = doc(db, "Vendors", user.uid);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const userData = docSnap.data();
        // Save user data and docId to localStorage
        localStorage.setItem("authUser", JSON.stringify(userData));
        localStorage.setItem("docId", docSnap.id);

        setAlertMessage("Login successful!");
        setAlertType("success");

        setTimeout(() => navigate(redirectUrl), 2000); // Redirect after 2 seconds
      }
    } else {
      setAlertMessage("Invalid credentials.");
      setAlertType("danger");
    }
    setIsSubmitting(false);
  };

  if (userProfile && !loading) {
    return <Navigate to={{ pathname: redirectUrl }} />;
  }

  return (
    <NonAuthLayoutWrapper>
      <Row className="justify-content-center my-auto">
        <Col sm={8} lg={6} xl={5} className="col-xxl-4">
          <div className="py-md-5 py-4">
            <AuthHeader
              title="Welcome Back!"
              subtitle="Sign in to continue to Doot."
            />

            {alertMessage && (
              <Alert color={alertType} toggle={() => setAlertMessage("")}>
                {alertMessage}
              </Alert>
            )}

            <Form
              onSubmit={handleSubmit(onSubmitForm)}
              className="position-relative"
            >
              {isSubmitting && <Loader />}
              <div className="mb-3">
                <FormInput
                  label="Email Address"
                  type="text"
                  name="email"
                  register={register}
                  errors={errors}
                  control={control}
                  labelClassName="form-label"
                  placeholder="Enter email"
                  className="form-control"
                />
              </div>

              <div className="mb-3">
                <FormInput
                  label="Password"
                  type="password"
                  name="password"
                  register={register}
                  errors={errors}
                  control={control}
                  labelClassName="form-label"
                  placeholder="Enter Password"
                  className="form-control"
                />
              </div>

              <div className="form-check form-check-info font-size-16">
                <input
                  title="checkbox"
                  placeholder=""
                  className="form-check-input"
                  type="checkbox"
                  id="remember-check"
                />
                <Label
                  className="form-check-label font-size-14"
                  htmlFor="remember-check"
                >
                  Remember me
                </Label>
              </div>

              <div className="text-center mt-4">
                <Button
                  color="primary"
                  className="w-100"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Processing..." : "Log In"}
                </Button>
              </div>
            </Form>

            <div className="mt-5 text-center text-muted">
              <p>
                Don't have an account?{" "}
                <Link
                  to="/auth-register"
                  className="fw-medium text-decoration-underline"
                >
                  Register
                </Link>
              </p>
            </div>
          </div>
        </Col>
      </Row>
    </NonAuthLayoutWrapper>
  );
};

export default Login;
