export enum LAYOUT_MODES {
  LIGHT = "light",
  DARK = "dark",
}

export enum CHATS_TABS {
  ARCHIVE = "acrhive",
  DEFAULT = "default",
}

export enum TABS {
  USERS = "pills-user",
  CHAT = "pills-chat",
  INVENTORY = "pills-inventory",
  SETTINGS = "pills-setting",
  ORDERS = "pills-order",
  TRANSACTIONS = "pills-transaction",
  VENDORS = "pills-vendor"

}
