import React, { useState, useEffect } from "react";
import { db } from "../../firebaseConfig";
import { collection, getDocs, doc, updateDoc, deleteDoc } from "firebase/firestore";
import UpdateVendor from "./UpdateVendor";

export interface Vendor {
    id?: string;
    fullName: string;
    email: string;
    mobileNumber: string;
    companyName: string;
}

export default function Vendors() {
    const [vendors, setVendors] = useState<Vendor[]>([]);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [vendorToUpdate, setVendorToUpdate] = useState<Vendor | null>(null);
    const [vendorToDelete, setVendorToDelete] = useState<string | null>(null);

    useEffect(() => {
        const fetchVendors = async () => {
            try {
                const vendorsCollection = collection(db, "Vendors");
                const vendorsSnapshot = await getDocs(vendorsCollection);
                const vendorsList = vendorsSnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                } as Vendor));
                setVendors(vendorsList);
            } catch (error) {
                console.error("Error fetching vendors: ", error);
            }
        };

        fetchVendors();
    }, []);

    const handleUpdateVendor = async (updatedVendor: Vendor) => {
        if (!updatedVendor.id) return;

        try {
            const vendorDocRef = doc(db, "Vendors", updatedVendor.id);
            await updateDoc(vendorDocRef, {
                fullName: updatedVendor.fullName,
                email: updatedVendor.email,
                mobileNumber: updatedVendor.mobileNumber,
                companyName: updatedVendor.companyName,
            });
            setVendors(vendors.map(vendor => vendor.id === updatedVendor.id ? updatedVendor : vendor));
            setShowUpdateModal(false);
        } catch (error) {
            console.error("Error updating vendor:", error);
        }
    };

    const handleDeleteVendor = async () => {
        if (!vendorToDelete) return;

        try {
            const vendorDocRef = doc(db, "Vendors", vendorToDelete);
            await deleteDoc(vendorDocRef);
            setVendors(vendors.filter(vendor => vendor.id !== vendorToDelete));
            setVendorToDelete(null);
        } catch (error) {
            console.error("Error deleting vendor:", error);
        }
    };

    return (
        <div className="main-panel">
            <div className="content">
                <div className="container-fluid mt-3 p-3">
                    <h4 className="page-title mb-4">Vendor Details</h4>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-tasks">
                                <div className="card-header d-flex justify-content-between align-items-center">
                                    <div className="card-body">
                                        {vendors.length > 0 ? (
                                            <div className="table-responsive px-5 py-4 table-striped">
                                                <table className="table table-bordered table-hover ">
                                                    <thead>
                                                        <tr>
                                                            <th>Full Name</th>
                                                            <th>Email</th>
                                                            <th>Mobile Number</th>
                                                            <th>Company Name</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {vendors.map(vendor => (
                                                            <tr key={vendor.id}>
                                                                <td>{vendor.fullName}</td>
                                                                <td>{vendor.email}</td>
                                                                <td>{vendor.mobileNumber}</td>
                                                                <td>{vendor.companyName}</td>
                                                                <td className="td-actions">
                                                                    <button
                                                                        className="btn btn-link btn-success"
                                                                        onClick={() => {
                                                                            setVendorToUpdate(vendor);
                                                                            setShowUpdateModal(true);
                                                                        }}
                                                                    >
                                                                        <i className="fas fa-edit"></i>
                                                                    </button>
                                                                    <button
                                                                        className="btn btn-link btn-danger"
                                                                        onClick={() => setVendorToDelete(vendor.id!)}
                                                                    >
                                                                        <i className="fas fa-trash-alt"></i>
                                                                    </button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        ) : (
                                            <p>No vendor details found.</p>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {showUpdateModal && vendorToUpdate && (
                    <UpdateVendor
                        vendor={vendorToUpdate}
                        onClose={() => setShowUpdateModal(false)}
                        onUpdate={handleUpdateVendor}
                    />
                )}
                {/* Delete Confirmation Modal */}
                {vendorToDelete && (
                    <div className="modal" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: 'rgba(0,0,0,0.5)' }}>
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">Confirm Deletion</h5>
                                    <button
                                        type="button"
                                        className="close"
                                        onClick={() => setVendorToDelete(null)}
                                        style={{ background: "none", border: "none", fontSize: "1.5rem", color: "#000" }}
                                    >
                                        &times;
                                    </button>
                                </div>
                                <div className="modal-body">
                                    Are you sure you want to delete this vendor?
                                </div>
                                <div className="modal-footer">
                                    <button
                                        className="btn btn-secondary"
                                        onClick={() => setVendorToDelete(null)}
                                    >
                                        Cancel
                                    </button>
                                    <button className="btn btn-danger" onClick={handleDeleteVendor}>
                                        Delete
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}
