import React, { useState } from 'react';

interface AddItemProps {
    onClose: () => void;
    onAdd: (item: Omit<Item, 'id'>) => void;
}

interface Item {
    id: string;
    name: string;
    Catalog: string;
    price: number;
    stock: string; // For dropdown values
    description: string; // New description property
}

export default function AddItem({ onClose, onAdd }: AddItemProps) {
    const [newItem, setNewItem] = useState<Omit<Item, 'id'>>({
        name: '',
        Catalog: '',
        price: 0,
        stock: 'Available',
        description: '' // Default value set to "Available"
    });

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        onAdd(newItem);
    };

    return (
        <div className="modal" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: 'rgba(0,0,0,0.5)' }}>
            <div className="modal-dialog" style={{ maxWidth: '500px', width: '100%' }}>
                <div className="modal-content" style={{ borderRadius: '8px', boxShadow: '0 4px 8px rgba(0,0,0,0.2)', transition: 'all 0.3s ease' }}>
                    <div className="modal-header" style={{ borderBottom: '1px solid #dee2e6', padding: '1rem' }}>
                        <h5 className="modal-title">Add New Item</h5>
                        <button type="button" className="close" onClick={onClose}
                            style={{
                                background: "none",
                                border: "none",
                                fontSize: "1.5rem",
                                color: "#000",
                                transition: 'all 0.3s ease',
                                cursor: 'pointer'
                            }}
                        >
                            <span>&times;</span>
                        </button>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="modal-body" style={{ padding: '1rem' }}>
                            <div className="form-group" style={{ marginBottom: '1rem' }}>
                                <label htmlFor="name" style={{ marginBottom: '0.5rem', display: 'block' }}>Name</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    value={newItem.name}
                                    onChange={(e) => setNewItem({ ...newItem, name: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="form-group" style={{ marginBottom: '1rem' }}>
                                <label htmlFor="Catalog" style={{ marginBottom: '0.5rem', display: 'block' }}>Catalog</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="Catalog"
                                    value={newItem.Catalog}
                                    onChange={(e) => setNewItem({ ...newItem, Catalog: e.target.value })}
                                    required
                                />
                            </div>
                            <div className="form-group" style={{ marginBottom: '1rem' }}>
                                <label htmlFor="price" style={{ marginBottom: '0.5rem', display: 'block' }}>Price</label>
                                <input
                                    type="number"
                                    className="form-control"
                                    id="price"
                                    value={newItem.price}
                                    onChange={(e) => setNewItem({ ...newItem, price: Number(e.target.value) })}
                                    required
                                />
                            </div>
                            <div className="form-group" style={{ marginBottom: '1rem' }}>
                                <label htmlFor="stock" style={{ marginBottom: '0.5rem', display: 'block' }}>Stock</label>
                                <select
                                    className="form-control"
                                    id="stock"
                                    value={newItem.stock}
                                    onChange={(e) => setNewItem({ ...newItem, stock: e.target.value })}
                                    required
                                >
                                    <option value="Available">Available</option>
                                    <option value="Out of Stock">Out of Stock</option>
                                </select>
                            </div>
                            <div className="form-group" style={{ marginBottom: '1rem' }}>
                                <label htmlFor="description" style={{ marginBottom: '0.5rem', display: 'block' }}>Description</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="description"
                                    value={newItem.description}
                                    onChange={(e) => setNewItem({ ...newItem, description: e.target.value })}
                                    required
                                />
                            </div>
                        </div>
                        <div className="modal-footer" style={{ borderTop: '1px solid #dee2e6', padding: '1rem' }}>
                            <button type="button" className="btn btn-secondary" onClick={onClose}
                                style={{
                                    borderRadius: '50px',
                                    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                                    transition: 'all 0.3s ease',
                                    padding: '0.5rem 1rem'
                                }}
                            >
                                Close
                            </button>
                            <button type="submit" className="btn btn-primary"
                                style={{
                                    borderRadius: '50px',
                                    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                                    transition: 'all 0.3s ease',
                                    padding: '0.5rem 1.4rem'
                                }}
                            >
                                Add Item
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}
