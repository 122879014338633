import React, { useState } from "react";

// OrderTypes.ts
interface Order {
    id?: string;
    customerName: string;
    orderDate: string;
    status: string;
    totalAmount: number;
}

interface UpdateOrderProps {
    order: Order;
    onClose: () => void;
    onUpdate: (order: Order) => void;
}

export default function UpdateOrder({ order, onClose, onUpdate }: UpdateOrderProps) {
    const [updatedOrder, setUpdatedOrder] = useState<Order>(order);
    const [errorMsg, setErrorMsg] = useState("");

    const handleSubmit = () => {
        if (
            !updatedOrder.customerName ||
            !updatedOrder.orderDate ||
            !updatedOrder.status ||
            updatedOrder.totalAmount <= 0
        ) {
            setErrorMsg("Please fill in all fields correctly.");
            return;
        }
        onUpdate(updatedOrder);
    };

    return (
        <div className="modal" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: 'rgba(0,0,0,0.5)' }}>
            <div className="modal-dialog" style={{ maxWidth: '500px', width: '100%' }}>
                <div className="modal-content" style={{ borderRadius: '8px', boxShadow: '0 4px 8px rgba(0,0,0,0.2)', transition: 'all 0.3s ease' }}>
                    <div className="modal-header" style={{ borderBottom: '1px solid #dee2e6', padding: '1rem' }}>
                        <h5 className="modal-title">Update Order</h5>
                        <button type="button" className="close" onClick={onClose}
                            style={{
                                background: "none",
                                border: "none",
                                fontSize: "1.5rem",
                                color: "#000",
                                borderRadius: '50px',
                                boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
                                transition: 'all 0.3s ease',
                                padding: '0.5rem',
                                cursor: 'pointer'
                            }}
                        >
                            <span>&times;</span>
                        </button>
                    </div>
                    <div className="modal-body" style={{ padding: '1rem' }}>
                        <div className="form-group" style={{ marginBottom: '1rem' }}>
                            <label htmlFor="customerName" style={{ marginBottom: '0.5rem', display: 'block' }}>Customer Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="customerName"
                                value={updatedOrder.customerName}
                                onChange={(e) =>
                                    setUpdatedOrder({
                                        ...updatedOrder,
                                        customerName: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div className="form-group" style={{ marginBottom: '1rem' }}>
                            <label htmlFor="orderDate" style={{ marginBottom: '0.5rem', display: 'block' }}>Order Date</label>
                            <input
                                type="date"
                                className="form-control"
                                id="orderDate"
                                value={updatedOrder.orderDate}
                                onChange={(e) =>
                                    setUpdatedOrder({
                                        ...updatedOrder,
                                        orderDate: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div className="form-group" style={{ marginBottom: '1rem' }}>
                            <label htmlFor="status" style={{ marginBottom: '0.5rem', display: 'block' }}>Status</label>
                            <select
                                className="form-control"
                                id="status"
                                value={updatedOrder.status}
                                onChange={(e) =>
                                    setUpdatedOrder({
                                        ...updatedOrder,
                                        status: e.target.value,
                                    })
                                }
                            >
                                <option value="">Select Status</option>
                                <option value="Pending">Pending</option>
                                <option value="Processing">Processing</option>
                                <option value="Completed">Completed</option>
                                <option value="Cancelled">Cancelled</option>
                            </select>
                        </div>
                        <div className="form-group" style={{ marginBottom: '1rem' }}>
                            <label htmlFor="totalAmount" style={{ marginBottom: '0.5rem', display: 'block' }}>Total Amount</label>
                            <input
                                type="number"
                                className="form-control"
                                id="totalAmount"
                                value={updatedOrder.totalAmount}
                                onChange={(e) =>
                                    setUpdatedOrder({
                                        ...updatedOrder,
                                        totalAmount: parseFloat(e.target.value),
                                    })
                                }
                            />
                        </div>
                        {errorMsg && <div className="alert alert-danger" style={{ marginTop: '1rem' }}>{errorMsg}</div>}
                    </div>
                    <div className="modal-footer" style={{ borderTop: '1px solid #dee2e6', padding: '1rem' }}>
                        <button type="button" className="btn btn-secondary" onClick={onClose}
                            style={{
                                borderRadius: '50px',
                                boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                                transition: 'all 0.3s ease',
                                padding: '0.5rem 1rem'
                            }}
                        >
                            Close
                        </button>
                        <button type="button" className="btn btn-primary" onClick={handleSubmit}
                            style={{
                                borderRadius: '50px',
                                boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                                transition: 'all 0.3s ease',
                                padding: '0.5rem 1rem'
                            }}
                        >
                            Update Order
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
