import { useState, useEffect } from "react";

// hooks
import { useRedux } from "../hooks/index";

// api
import { getLoggedinUser } from "../api/apiCore";
import { createSelector } from "reselect";
//utils
import { divideByKey } from "../utils";

//firebase
import { auth, db } from "../firebaseConfig";
import {
  doc,
  setDoc,
  getDoc,
  onSnapshot,
  collection,
  orderBy,
  updateDoc,
  query,
  getDocs,
  addDoc,
  serverTimestamp,
  DocumentData,
} from "firebase/firestore";

const useProfile = () => {
  // global store
  const { useAppSelector } = useRedux();

  // const { settings } = useAppSelector(state => ({
  //   settings: state.Settings.settings,
  // }));

  const errorData = createSelector(
    (state: any) => state.Settings,
    state => ({
      settings: state.settings,
    }),
  );
  // Inside your component
  const { settings } = useAppSelector(errorData);
  const image = settings.basicDetails && settings.basicDetails.profile;
  const userProfileSession = getLoggedinUser();
  const [loading] = useState(userProfileSession ? false : true);
  const [userProfile, setUserProfile] = useState(
    userProfileSession ? { ...userProfileSession, profileImage: image } : null,
  );
  useEffect(() => {
    const userProfileSession = getLoggedinUser();
    setUserProfile(
      userProfileSession
        ? { ...userProfileSession, profileImage: image }
        : null,
    );
  }, [image]);

  return { userProfile, loading };
};

const getAllVendors = async () => {
  const user_uid = localStorage.getItem("docId") as string;
  var vendors: DocumentData[] = [];
  const vendorsData = await getDocs(collection(db, "Vendors"));
  vendorsData.forEach(doc => {
    const data = doc.data();
    if (
      data.email &&
      data.mobileNumber &&
      data.password === undefined &&
      doc.id !== user_uid
    ) {
      vendors.push({ ...data, id: doc.id });
    }
  });

  return vendors;
};

const useContacts = () => {
  const [loading, setLoading] = useState(true);
  const [contacts, setContacts] = useState<Array<any>>([]);
  const [categorizedContacts, setCategorizedContacts] = useState<Array<any>>(
    [],
  );

  const getData = async () => {
    const vendors = await getAllVendors();
    setContacts(vendors);
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (contacts.length > 0) {
      const formattedContacts = divideByKey("fullName", contacts);
      setCategorizedContacts(formattedContacts);
    }
  }, [contacts]);

  const totalContacts = (categorizedContacts || []).length;
  return { categorizedContacts, totalContacts };
};

const useConversationUserType = () => {
  // global store
  const { useAppSelector } = useRedux();

  // const { chatUserDetails } = useAppSelector(state => ({
  //   chatUserDetails: state.Chats.chatUserDetails,
  // }));

  const errorData = createSelector(
    (state: any) => state.Chats,
    state => ({
      chatUserDetails: state.chatUserDetails,
    }),
  );
  // Inside your component
  const { chatUserDetails } = useAppSelector(errorData);

  const [isChannel, setIsChannel] = useState<boolean>(false);
  useEffect(() => {
    setIsChannel(chatUserDetails.isChannel ? true : false);
  }, [chatUserDetails]);

  return { isChannel };
};
export { useProfile, useContacts, useConversationUserType };
