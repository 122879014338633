import React, { useEffect, useRef, useCallback, useState } from "react";
import { auth, db } from "../../firebaseConfig";
// hooks
import { useRedux, useChat } from "../../hooks/index";

// hooks
import { useProfile } from "../../hooks";
import { createSelector } from "reselect";
// components
import AppSimpleBar from "../../components/AppSimpleBar";
import Loader from "../../components/Loader";
import Message from "./Message";
// import Day from "./Day";

// interface
import { MessagesTypes } from "../../data/messages";
import ForwardModal from "../../components/ForwardModal";
import { Entry, Message as NewMessage } from "../../types/Chat";

// actions
import { forwardMessage, deleteImage } from "../../redux/actions";
import {
  collection,
  onSnapshot,
  query,
  orderBy,
  addDoc,
  serverTimestamp,
} from "firebase/firestore";

interface ConversationProps {
  chatUserConversations: any;
  chatUserDetails: any;
  onDelete: (messageId: string | number) => any;
  onSetReplyData: (reply: null | any | undefined) => void;
  isChannel: boolean;
}
const Conversation = ({
  chatUserDetails,
  chatUserConversations,
  onDelete,
  onSetReplyData,
  isChannel,
}: ConversationProps) => {
  // global store
  const { dispatch, useAppSelector } = useRedux();
  const chat_id = localStorage.getItem("chat_id");
  const user_id = auth.currentUser?.uid;
  // const { loading, messages } = useChat(chat_id !== null ? chat_id : undefined);
  const [messages, setMessages] = useState<any[]>([]);
  // Realtime snapshot listener
  useEffect(() => {
    // console.log({ user_id }, { chat_id });

    if (!user_id || !chat_id) return;

    const messagesRef = collection(
      db,
      "Vendors",
      user_id,
      "Chats",
      chat_id,
      "Messages",
    );
    const q = query(messagesRef, orderBy("timestamp", "asc"));

    const unsubscribe = onSnapshot(q, snapshot => {
      // console.log(snapshot);
      const messagesData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMessages(messagesData);
    });

    // Cleanup function to unsubscribe from snapshot listener
    return () => unsubscribe();
  }, [user_id, chat_id]);
  // console.log({ msgList });
  // console.log({ messages });
  const { userProfile } = useProfile();

  const errorData = createSelector(
    (state: any) => state.Chats,
    state => ({
      getUserConversationsLoading: state.getUserConversationsLoading,
      isMessageForwarded: state.isMessageForwarded,
    }),
  );
  // Inside your component
  const { getUserConversationsLoading, isMessageForwarded } =
    useAppSelector(errorData);

  // const messages =
  //   chatUserConversations.messages && chatUserConversations.messages.length
  //     ? chatUserConversations.messages
  //     : [];

  const ref = useRef<any>();
  const scrollElement = useCallback(() => {
    if (ref && ref.current) {
      const listEle = document.getElementById("chat-conversation-list");
      let offsetHeight = 0;
      if (listEle) {
        offsetHeight = listEle.scrollHeight - window.innerHeight + 250;
      }
      if (offsetHeight) {
        ref.current
          .getScrollElement()
          .scrollTo({ top: offsetHeight, behavior: "smooth" });
      }
    }
  }, [ref]);

  function extractUrls(text: string) {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    const urls = text.match(urlRegex);
    return urls || [];
  }

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.recalculate();
    }
  }, []);

  useEffect(() => {
    if (messages) {
      scrollElement();
    }
  }, [messages, scrollElement]);

  /*
  forward message
  */
  const [forwardData, setForwardData] = useState<
    null | MessagesTypes | undefined
  >();
  const [isOpenForward, setIsOpenForward] = useState<boolean>(false);
  const onOpenForward = (message: MessagesTypes) => {
    setForwardData(message);
    setIsOpenForward(true);
  };
  const onCloseForward = () => {
    setIsOpenForward(false);
  };

  const onForwardMessage = (data: any) => {
    const params = {
      contacts: data.contacts,
      message: data.message,
      forwardedMessage: forwardData,
    };
    dispatch(forwardMessage(params));
  };
  useEffect(() => {
    if (isMessageForwarded) {
      setIsOpenForward(false);
    }
  }, [isMessageForwarded]);

  // useEffect(() => {
  // createChat();
  // }, [chatUserDetails]);

  /*
  image delete
  */
  const onDeleteImage = (
    messageId: string | number,
    imageId: string | number,
  ) => {
    dispatch(deleteImage(chatUserDetails.id, messageId, imageId));
  };
  return (
    <AppSimpleBar
      scrollRef={ref}
      className="chat-conversation p-3 p-lg-4 positin-relative"
    >
      {getUserConversationsLoading && <Loader />}
      <ul
        className="list-unstyled chat-conversation-list"
        id="chat-conversation-list"
      >
        {(messages || []).map((message: any, key: number) => {
          // const isFromMe = message.entry
          //   ? message.entry[0].id == user_id
          //   : message.senderMobile == userProfile.mobileNumber;
          const isFromMe = message.entry ? false : true;
          const validMessage = message.entry
            ? {
                ...message.entry[0].changes[0].value.messages[0],
                time: message.timestamp,
              }
            : message;
          const msg_id = message.id;
          const sender_id = message.entry
            ? message.entry[0].id
            : message.senderId;
          return (
            <Message
              msg_id={msg_id}
              sender_id={sender_id}
              isLeagay={message.entry ? true : false}
              message={validMessage}
              key={key}
              chatUserDetails={chatUserDetails}
              onDelete={onDelete}
              onSetReplyData={onSetReplyData}
              isFromMe={isFromMe}
              onOpenForward={onOpenForward}
              isChannel={isChannel}
              onDeleteImage={onDeleteImage}
            />
          );
        })}
        {/*  <Day /> */}
      </ul>
      {isOpenForward && (
        <ForwardModal
          isOpen={isOpenForward}
          onClose={onCloseForward}
          forwardData={forwardData}
          chatUserDetails={chatUserDetails}
          onForward={onForwardMessage}
        />
      )}
    </AppSimpleBar>
  );
};

export default Conversation;
