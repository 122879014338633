// constants
import { TABS } from "../../constants/index";

export interface MenuItemType {
  id: number;
  key: string;
  icon: string;
  tooltipTitle: string;
  className?: string;
  tabId:
  | TABS.CHAT
  // | TABS.SETTINGS
  | TABS.USERS
  | TABS.INVENTORY
  | TABS.ORDERS
  | TABS.TRANSACTIONS
  | TABS.VENDORS

}

const MENU_ITEMS: MenuItemType[] = [
  // {
  //   id: 1,
  //   key: "pills-user-tab",
  //   icon: "bx bx-user-circle",
  //   tooltipTitle: "Profile",
  //   className: "d-none d-lg-block",
  //   tabId: TABS.USERS,
  // },
  {
    id: 2,
    key: "pills-chat-tab",
    icon: "bx bx-conversation",
    tooltipTitle: "Chats",
    tabId: TABS.CHAT,
  },
  // {
  //   id: 3,
  //   key: "pills-setting-tab",
  //   icon: "bx bx-cog",
  //   tooltipTitle: "Settings",
  //   tabId: TABS.SETTINGS,
  // },
  {
    id: 4, // New menu item for Inventory
    key: "pills-inventory-tab",
    icon: "bx bx-box",
    tooltipTitle: "Inventory",
    tabId: TABS.INVENTORY, // Add Inventory tabId here
  },
  {
    id: 5, // New Orders Tab
    key: "pills-orders-tab",
    icon: "bx bx-receipt",
    tooltipTitle: "Orders",
    tabId: TABS.ORDERS,
  },
  {
    id: 6, // New Transactions Tab
    key: "pills-transactions-tab",
    icon: "bx bx-money",
    tooltipTitle: "Transactions",
    tabId: TABS.TRANSACTIONS,
  },
  {
    id: 7, // New Transactions Tab
    key: "pills-vendors-tab",
    icon: "bx bx-store",
    tooltipTitle: "Vendors",
    tabId: TABS.VENDORS,
  },
];

export { MENU_ITEMS };
