// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBnpwOjCWJnnv6h8egbHBDF1MjyRXASd0o",
  authDomain: "tryorderbotprod.firebaseapp.com",
  projectId: "tryorderbotprod",
  storageBucket: "tryorderbotprod.firebasestorage.app",
  messagingSenderId: "295767615194",
  appId: "1:295767615194:web:d58de1c7eb9b232d2a27e3",
  measurementId: "G-4P17BRMPWJ"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Export Auth and Firestore
export const auth = getAuth(app);
export const db = getFirestore(app);
