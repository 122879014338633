import React, { useState } from "react";

interface Transaction {
    id?: string;
    customerName: string;
    transactionDate: string;
    status: string;
    amount: number;
}

interface AddTransactionProps {
    onClose: () => void;
    onAdd: (transaction: Transaction) => void;
}

export default function AddTransaction({ onClose, onAdd }: AddTransactionProps) {
    const [newTransaction, setNewTransaction] = useState<Transaction>({
        customerName: "",
        transactionDate: "",
        status: "",
        amount: 0,
    });
    const [errorMsg, setErrorMsg] = useState("");

    const handleSubmit = () => {
        if (
            !newTransaction.customerName ||
            !newTransaction.transactionDate ||
            !newTransaction.status ||
            newTransaction.amount <= 0
        ) {
            setErrorMsg("Please fill in all fields correctly.");
            return;
        }
        onAdd(newTransaction);
    };

    return (
        <div className="modal" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', backgroundColor: 'rgba(0,0,0,0.5)' }}>
            <div className="modal-dialog" style={{ maxWidth: '500px', width: '100%' }}>
                <div className="modal-content" style={{ borderRadius: '8px', boxShadow: '0 4px 8px rgba(0,0,0,0.2)', transition: 'all 0.3s ease' }}>
                    <div className="modal-header" style={{ borderBottom: '1px solid #dee2e6', padding: '1rem' }}>
                        <h5 className="modal-title">Add New Transaction</h5>
                        <button type="button" className="close" onClick={onClose}
                            style={{
                                background: "none",
                                border: "none",
                                fontSize: "1.5rem",
                                color: "#000",
                                cursor: 'pointer'
                            }}
                        >
                            <span>&times;</span>
                        </button>
                    </div>
                    <div className="modal-body" style={{ padding: '1rem' }}>
                        <div className="form-group" style={{ marginBottom: '1rem' }}>
                            <label htmlFor="customerName" style={{ marginBottom: '0.5rem', display: 'block' }}>Customer Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="customerName"
                                placeholder="Enter customer name"
                                value={newTransaction.customerName}
                                onChange={(e) =>
                                    setNewTransaction({
                                        ...newTransaction,
                                        customerName: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div className="form-group" style={{ marginBottom: '1rem' }}>
                            <label htmlFor="transactionDate" style={{ marginBottom: '0.5rem', display: 'block' }}>Transaction Date</label>
                            <input
                                type="date"
                                className="form-control"
                                id="transactionDate"
                                value={newTransaction.transactionDate}
                                onChange={(e) =>
                                    setNewTransaction({
                                        ...newTransaction,
                                        transactionDate: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div className="form-group" style={{ marginBottom: '1rem' }}>
                            <label htmlFor="status" style={{ marginBottom: '0.5rem', display: 'block' }}>Status</label>
                            <select
                                className="form-control"
                                id="status"
                                value={newTransaction.status}
                                onChange={(e) =>
                                    setNewTransaction({
                                        ...newTransaction,
                                        status: e.target.value,
                                    })
                                }
                            >
                                <option value="">Select Status</option>
                                <option value="Pending">Pending</option>
                                <option value="Processing">Processing</option>
                                <option value="Completed">Completed</option>
                                <option value="Failed">Failed</option>
                            </select>
                        </div>
                        <div className="form-group" style={{ marginBottom: '1rem' }}>
                            <label htmlFor="amount" style={{ marginBottom: '0.5rem', display: 'block' }}>Amount</label>
                            <input
                                type="number"
                                className="form-control"
                                id="amount"
                                placeholder="Enter amount"
                                value={newTransaction.amount}
                                onChange={(e) =>
                                    setNewTransaction({
                                        ...newTransaction,
                                        amount: parseFloat(e.target.value),
                                    })
                                }
                            />
                        </div>
                        {errorMsg && <div className="alert alert-danger" style={{ marginTop: '1rem' }}>{errorMsg}</div>}
                    </div>
                    <div className="modal-footer" style={{ borderTop: '1px solid #dee2e6', padding: '1rem' }}>
                        <button type="button" className="btn btn-secondary" onClick={onClose}
                            style={{
                                borderRadius: '50px',
                                boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                                transition: 'all 0.3s ease',
                                padding: '0.5rem 1rem'
                            }}
                        >
                            Close
                        </button>
                        <button type="button" className="btn btn-primary" onClick={handleSubmit}
                            style={{
                                borderRadius: '50px',
                                boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
                                transition: 'all 0.3s ease',
                                padding: '0.5rem 1rem'
                            }}
                        >
                            Add Transaction
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
